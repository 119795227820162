import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { catchError, map, Observable, of, startWith, tap } from "rxjs";
import { upload, Upload } from "../../partials/upload/upload";
import {
  Country,
  Currency,
  EvidenceAddition,
  EvidenceInfo,
  Incident,
  Incidents,
  Invoice,
  Login,
  Offer,
  Package,
  ParamsCreateTransaction,
  ScamInfo,
  Transaction,
  UserEntity,
  VictimInfo,
  IncidentReport
} from "../models/safe-crypto.models";

@Injectable({
  providedIn: 'root'
})
export class SafeCryptoService {
  public actualIncidentId!: string;
  private _apiUrl: string = environment.apiUrl;
  private _httpClient: HttpClient = inject(HttpClient);

  login(body: { telegramChatId: string, telegramUserId: string, telegramUsername: string, fullName: string }): Observable<Login> {
    return this._httpClient.post<Login>(`${ this._apiUrl }/api/v1/users/login`, body).pipe(
      tap(({ actualIncidentId, sessionId  }) => {
        if (sessionId) {
          localStorage.setItem('token', sessionId);
        }
        if (actualIncidentId) {
          this.actualIncidentId = actualIncidentId;
        }
      })
    );
  }

  putUserAcceptTerms(userId: string): Observable<null> {
    return this._httpClient.put<null>(`${ this._apiUrl }/api/v1/users/${ userId }/accept-terms`, {});
  }

  putLeadStatus(leadStatus: 'PREVIEW' | 'SUBMITTED' | 'SCAM' | 'VICTIM' | 'TRANSACTION' | 'EVIDENCE'): Observable<null> {
    this._checkActualIncidentId();
    return this._httpClient.put<null>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/lead-status`, { leadStatus: leadStatus });
  }

  getUser(userId: string): Observable<UserEntity> {
    return this._httpClient.get<UserEntity>(`${ this._apiUrl }/api/v1/users/${ userId }`).pipe(tap(({ actualIncidentId }) => {
      if (actualIncidentId) {
        this.actualIncidentId = actualIncidentId;
      }
    }));
  }

  getGeo(userId: string): Observable<{actualGeo: string}> {
    return this._httpClient.get<{actualGeo: string}>(`${ this._apiUrl }/api/v1/users/${userId}/geo`);
  }

  putGeo(userId: string, country: string): Observable<any> {
    return this._httpClient.put<any>(`${ this._apiUrl }/api/v1/users/${userId}/geo`, {country});
  }

  putChoosePackage(incidentId: string, offerId: string, packageId: string): Observable<{isInvoiceCreated: boolean, invoice: Invoice}> {
    return this._httpClient.put<{isInvoiceCreated: boolean, invoice: Invoice}>(`${ this._apiUrl }/api/v1/incidents/${incidentId}/offers/${offerId}/packages/${packageId}/choose`, {});
  }

  putApproveUpgrades(incidentId: string, offerId: string): Observable<any> {
    return this._httpClient.put<any>(`${ this._apiUrl }/api/v1/incidents/${incidentId}/offers/${offerId}/approve-upgrades`, {});
  }

  postCreateIncident(userId: string): Observable<{ id: string }> {
    const body = { userId }
    return this._httpClient.post<{ id: string }>(`${ this._apiUrl }/api/v1/incidents`, body).pipe(tap(({ id }) => {
      this.actualIncidentId = id
    }));
  }

  cancelIncident(incidentId: string): Observable<null> {
    return this._httpClient.put<null>(`${ this._apiUrl }/api/v1/incidents/${ incidentId }/lead-status`, { leadStatus: 'CANCELED' });
  }

  getIncident(): Observable<Incident> {
    this._checkActualIncidentId();
    return this._httpClient.get<Incident>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }`);
  }

  getScamInfoIncident(): Observable<ScamInfo> {
    this._checkActualIncidentId();
    return this._httpClient.get<ScamInfo>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/scam`);
  }

  patchScamInfoIncident(body: ScamInfo): Observable<null> {
    this._checkActualIncidentId();
    return this._httpClient.patch<null>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/scam`, body);
  }

  patchVictimInfoIncident(body: VictimInfo): Observable<null> {
    this._checkActualIncidentId();
    return this._httpClient.patch<null>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/victim`, body);
  }

  getTransactions(): Observable<Transaction[]> {
    this._checkActualIncidentId();
    return this._httpClient.get<Transaction[]>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/transactions`);
  }

  postCreateTransactions(body: ParamsCreateTransaction): Observable<Transaction[]> {
    this._checkActualIncidentId();
    return this._httpClient.post<Transaction[]>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/transactions`, body);
  }

  deleteCreateTransactions(idTransaction: string): Observable<null> {
    this._checkActualIncidentId();
    return this._httpClient.delete<null>(`${ this._apiUrl }/api/v1/incidents/${this.actualIncidentId}/transactions/${idTransaction}`);
  }

  getCountry(): Observable<Country[]> {
    return this._httpClient.get<Country[]>(`${ this._apiUrl }/api/v1/countries`);
  }

  getCurrency(): Observable<Currency[]> {
    return this._httpClient.get<Currency[]>(`${ this._apiUrl }/api/v1/networks`);
  }

  networkValid(network: string, body: {hash?: string, wallet?: string}): Observable<{isValid: boolean}> {
    let params = new HttpParams();
    body.hash && (params = params.append('hash', body.hash));
    body.wallet && (params = params.append('wallet', body.wallet));
    return this._httpClient.get<{isValid: boolean}>(`${ this._apiUrl }/api/v1/networks/${network}/valid`, {params});
  }

  getInfoTransaction(hash: string, network: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        hash, network
      }
    });
    return this._httpClient.get<any>(`${ this._apiUrl }/api/v1/transactions`, {params});
  }

  // evidence-info-controller
  getEvidence(): Observable<EvidenceInfo> {
    this._checkActualIncidentId();
    return this._httpClient.get<EvidenceInfo>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence`);
  }

  putEvidence(body: Partial<EvidenceInfo>): Observable<never> {
    this._checkActualIncidentId();
    return this._httpClient.put<never>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence`, body);
  }

  postEvidence(body: Omit<Partial<EvidenceInfo>, 'id'>): Observable<{ id: string }> {
    this._checkActualIncidentId();
    return this._httpClient.post<{
      id: string
    }>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence`, body);
  }

  patchEvidence(body: Partial<EvidenceInfo>): Observable<never> {
    this._checkActualIncidentId();
    return this._httpClient.patch<never>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence`, body);
  }

  getEvidenceById(id: string): Observable<EvidenceInfo> {
    this._checkActualIncidentId();
    return this._httpClient.get<EvidenceInfo>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence/${ id }`);
  }

  putEvidenceById(id: string, body: Partial<never>): Observable<EvidenceInfo> {
    this._checkActualIncidentId();
    return this._httpClient.put<never>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence/${ id }`, body);
  }

  patchEvidenceById(id: string, body: Partial<never>): Observable<EvidenceInfo> {
    this._checkActualIncidentId();
    return this._httpClient.patch<never>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence/${ id }`, body);
  }

  getIncidents(userId: string): Observable<Incidents> {
    const params = new HttpParams({
      fromObject: {
        userId
      }
    });
    return this._httpClient.get<Incidents>(`${ this._apiUrl }/api/v1/incidents`, { params });
  }

  getIncidentWithId(incidentId: string): Observable<Incident> {
    return this._httpClient.get<Incident>(`${ this._apiUrl }/api/v1/incidents/${incidentId}`);
  }

  getOffer(incidentId: string, offerId: string): Observable<Offer> {
    return this._httpClient.get<Offer>(`${ this._apiUrl }/api/v1/incidents/${incidentId}/offers/${offerId}`);
  }

  getInvoice({incidentId, offerId, invoiceId}: {incidentId: string, offerId: string, invoiceId: string}): Observable<Invoice> {
    return this._httpClient.get<Invoice>(`${ this._apiUrl }/api/v1/incidents/${incidentId}/offers/${offerId}/invoices/${invoiceId}`);
  }

  getPackage(packageId: string): Observable<Package> {
    return this._httpClient.get<Package>(`${ this._apiUrl }/api/v1/offer-packages/${packageId}`);
  }

  postRequestOffer(incidentId: string): Observable<any> {
    return this._httpClient.post<any>(`${ this._apiUrl }/api/v1/incidents/${incidentId}/request-offer`, {});
  }

  getReports(incidentId: string): Observable<IncidentReport[]> {
    return this._httpClient.get<IncidentReport[]>(`${ this._apiUrl }/api/v1/incidents/${incidentId}/reports`);
  }

  getFile(id: string): Observable<Blob> {
    return this._httpClient.get(`${this._apiUrl}/api/v1/files/${id}`, {
      responseType: "blob",
    });
  }

  downloadReport({ id, filename, type }: { id: string; filename: string; type?: string }): Observable<boolean> {
    return this._httpClient.get(`${this._apiUrl}/api/v1/files/${id}`, {
      responseType: "blob",
    }).pipe( startWith(true), map((data) => {
          if (typeof data !== 'boolean') {
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob([data], { type })
            );
            downloadLink.setAttribute("download", filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            return false;
          }
          return true;
      }),
      catchError((err) => {
          console.log(err);
          return of(false);
      })
    );
  }

  // ------------------------

  // evidence-addition-controller
  getEvidenceAdditionById(evidenceId: string, id: string): Observable<EvidenceAddition> {
    this._checkActualIncidentId();
    return this._httpClient.get<EvidenceAddition>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence/${ evidenceId }/additions/${ id }`);
  }

  putEvidenceAdditionById(evidenceId: string, id: string, body: Partial<EvidenceAddition>): Observable<EvidenceAddition> {
    this._checkActualIncidentId();
    return this._httpClient.put<EvidenceAddition>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence/${ evidenceId }/additions/${ id }`, body);
  }

  deleteEvidenceAdditionById(evidenceId: string, id: string): Observable<never> {
    this._checkActualIncidentId();
    return this._httpClient.delete<never>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence/${ evidenceId }/additions/${ id }`);
  }

  postEvidenceAddition(evidenceId: string, body: Partial<EvidenceAddition>): Observable<EvidenceAddition> {
    this._checkActualIncidentId();
    return this._httpClient.post<EvidenceAddition>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence/${ evidenceId }/additions`, body);
  }

  postEvidenceAdditionUploadFile(evidenceId: string, file: File): Observable<Upload<{ id: string }>> {
    this._checkActualIncidentId();
    const body = new FormData();
    body.append('file', file);

    const params = new HttpParams({
      fromObject: {
        name: file.name,
        fileType: file.type
      }
    });

    return this._httpClient.post<{
      id: string
    }>(`${ this._apiUrl }/api/v1/incidents/${ this.actualIncidentId }/evidence/${ evidenceId }/additions/uploadFile`,
      body,
      {
        params,
        reportProgress: true,
        observe: 'events',
      }).pipe(upload<{ id: string }>());
  }

  // ------------------------

  private _checkActualIncidentId(): void {
    if (!this.actualIncidentId) {
      throw new Error("In Service not find incident Id");
    }
  }

  testLeadStatus(id: string): Observable<null> {
    this._checkActualIncidentId();
    return this._httpClient.put<null>(`${ this._apiUrl }/api/v1/incidents/${ id }/lead-status`, { leadStatus: 'CANCELED' });
  }
}
